import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg'
import { ReactComponent as ArrowUp } from 'assets/arrow-up.svg'
import cover1 from 'assets/cover/1.jpg'
import cover2 from 'assets/cover/2.jpg'
import cover3 from 'assets/cover/3.jpg'
import cover4 from 'assets/cover/4.jpg'
import ENDPOINTS from 'constants/endpoints'

import * as S from './styles'

const handleOpenTourLink = () => {
  window.open(ENDPOINTS.TOUR_LINK.ROOT, '_blank')
}

const PHOTOS = [
  { src: cover1 },
  { src: cover2, onCLick: handleOpenTourLink },
  { src: cover3 },
  { src: cover4, onCLick: handleOpenTourLink },
]

type CoverProps = {
  topGallery?: boolean
}

export default function Cover(props: CoverProps) {
  const [current, setCurrent] = useState<number>(0)

  const intervalRef = useRef<NodeJS.Timer>()
  const previous = useCallback(() => {
    setCurrent((prevState) => {
      if (prevState === 0) {
        return PHOTOS.length - 1
      }

      return prevState - 1
    })
  }, [])

  const next = useCallback(() => {
    setCurrent((prevState) => {
      if (prevState === PHOTOS.length - 1) {
        return 0
      }

      return prevState + 1
    })
  }, [])

  const startInterval = useCallback(() => {
    const intervalId = setInterval(() => {
      next()
    }, 10000)
    intervalRef.current = intervalId
  }, [next])

  const resetInterval = useCallback(() => {
    clearInterval(intervalRef.current)
    startInterval()
  }, [startInterval])

  useEffect(() => {
    startInterval()

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [startInterval])

  return (
    <S.Container
      topGallery={props.topGallery}
      style={{ cursor: PHOTOS[current].onCLick ? 'pointer' : 'default' }}
    >
      <S.PhotosContainer onClick={() => PHOTOS[current].onCLick?.()}>
        {PHOTOS[current].src.includes('.mp4') ? (
          <S.VideoContainer>
            <S.CurrentVideo autoPlay loop muted>
              <source src={PHOTOS[current].src} type="video/mp4"></source>
            </S.CurrentVideo>
          </S.VideoContainer>
        ) : (
          <S.Current
            imageSource={PHOTOS[current].src}
            onClick={(event) => {
              event.stopPropagation()
            }}
          />
        )}

        <S.NavigationContainer>
          <ArrowUp
            onClick={(event: MouseEvent<HTMLDivElement, MouseEvent>) => {
              event.stopPropagation()
              resetInterval()
              previous()
            }}
          />

          <ArrowDown
            onClick={(event: MouseEvent<HTMLDivElement, MouseEvent>) => {
              event.stopPropagation()
              resetInterval()
              next()
            }}
          />
        </S.NavigationContainer>
      </S.PhotosContainer>
    </S.Container>
  )
}
